import React from "react";
import Layout from "../components/Layout";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { options } from "../styles/TypographySettings";
import Paper from "@material-ui/core/Paper";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { navigate } from "@reach/router";
import { CardActionArea } from "@material-ui/core";

const blogList = (props: any) => {
  const context = props.pageContext;
  const _blogEntries = context.blogEntries;
  const blogEntries = _blogEntries.map(entry => {
    const {
      createdAt,
      id,
      metaDescription,
      pageTitle,
      permalink,
      searchIndex,
      updatedAt
    } = entry.node;
    const cardTitle = pageTitle;
    const date = new Date(createdAt).toLocaleDateString();
    const cardText = metaDescription.internal.content;
    const url = `/blog/${permalink}`;

    return (
      <Card className="page__card">
        <CardActionArea onClick={() => navigate(url)}>
          <CardContent>
            <Typography variant="title">{cardTitle}</Typography>
            <Typography variant="subheading">Posted {date}</Typography>
            {/* <Typography variant="body1">{cardText}</Typography> */}
          </CardContent>
        </CardActionArea>
      </Card>
    );
  })
  const _copy: any = context.childContentfulPageCopyRichTextNode.copy;
  const copy = documentToReactComponents(_copy, options);
  const {
    createdAt,
    id,
    pageTitle,
    showTitle,
    searchIndex,
    metaDescription
  } = context;
  const SEO = { searchIndex, metaDescription };
  const content = (
    <Paper className="page">
      <Grid container className="page__content">
        <Grid item className="page__col">
          <div className="page__section">
            {showTitle ? (
              <Typography variant="h2">{pageTitle}</Typography>
            ) : null}
            {copy}
            <div className="page__blog-list">
              {blogEntries}
            </div>
          </div>
        </Grid>
      </Grid>
    </Paper>
  );
  return (
    <Layout
      components={[{ columns: 12, content, className: "blog-entry" }]}
      authRequired={false}
      seo={SEO}
    />
  );
};

export default blogList;
